import styled from '@emotion/styled'
import { up } from '../../utils'

export function AccessibilityLinks() {
  return (
    <Wrapper>
      <Skip
        href="#"
        onClick={e => {
          e.preventDefault()
          const container: HTMLElement | null = document.querySelector('main h1')

          if (container) {
            container.tabIndex = -1
            container.focus()
            setTimeout(() => container.removeAttribute('tabIndex'), 1000)
          }
        }}
      >
        Skip to main content
      </Skip>
    </Wrapper>
  )
}

const Wrapper = styled.div({
  position: 'absolute',
  top: 75,
  left: 10,
  zIndex: 9999,
  [up('sm')]: {
    left: 80,
  },
})

const Skip = styled.a({
  ':not(:focus)': {
    clipPath: 'inset(100%)',
    overflow: 'hidden',
    position: 'absolute',
    padding: 0,
  },
  ':focus': {
    border: '1px solid #1E6DEB',
    borderRadius: 2,
    fontSize: 14,
    padding: '3px 6px',
    display: 'inline-block',
    textAlign: 'center',
  },
})
